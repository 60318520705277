@import "src/styles/mixins"

.wrapper
    display: flex
    position: relative
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100vh
    width: 100%

    @include laptop
        padding: 0 16px

    @include mobile
        height: 100%

.container
    width: 768px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    @include laptop
        justify-content: center
        width: 100%

.questions
    margin-top: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: calc( 100vh - 128px)

    @include mobile
        margin-top: 40px 
        height: auto

.chips
    display: flex
    gap: 12px
    flex-wrap: wrap
    justify-content: center
    margin-top: 32px
    --tk-button-size-M-padding: 0

.chip
    cursor: pointer !important

.input
    width: 100%
    padding: 20px 0

    @include mobile
        padding: 40px 0
   
.controls
    --tk-button-size-M-padding: 0 12px
    width: 100%
    display: flex
    gap: 12px
    margin-top: auto
    align-items: center

.button
    height: 48px

    &_delete
        position: absolute
        right: 20px
        top: 40px

        @include laptop
            top: 4px
            right: 0

.messages
    display: flex
    flex-direction: column
    gap: 40px
    padding: 40px 0 20px
    width: 100%
    height: calc( 100vh - 128px)
    overflow-y: scroll
    +invisible-scrollbar()

    @include mobile
        padding-top: 60px
        height: auto
