@import "src/styles/mixins"

.message
    display: flex
    gap: 12px

    &__in
        justify-content: flex-end

    & img
        border-radius: 50%

    &__text
        display: inline-block
        padding: 14px 16px
        background-color: #EDEFF2
        border-radius: 8px
        color: $c-text-500
        font-size: 14px
        line-height: 20px
        font-weight: 400

        & ul
            list-style-type: disc
            padding-left: 20px
            margin-bottom: 20px

        & ol
            list-style-type: decimal
            padding-left: 20px
            margin-bottom: 20px

        & h3 
            font-size: 24px
            line-height: 32px
            font-weight: bold
            margin-bottom: 10px

        & h4 
            font-size: 20px
            line-height: 24px
            font-weight: bold
            margin-bottom: 15px

        & strong 
            font-weight: bold

        & p 
            margin-bottom: 14px

        & li 
            margin-bottom: 6px

    &__icon
        flex-shrink: 0




