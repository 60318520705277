@import "src/styles/mixins"

.wrapper
    padding: 20px 20px 40px
    border-right: 1px solid $c-stroke-200
    width: 300px
    height: 100vh

    @include mobile
        width: 100%
        height: auto
        border-right: none
        border-bottom: 1px solid $c-stroke-200

.container 
  display: flex
  flex-direction: column
  justify-content: space-between
  height: calc( 100vh - 104px )

  @include mobile
    height: auto


.assists
    display: flex
    flex-direction: column
    gap: 32px
    margin-top: 40px
    color: $c-text-400

    @include mobile
        flex-direction: row
        flex-wrap: wrap
        margin-bottom: 40px

.list
    margin-top: 12px
    display: flex
    flex-direction: column
    gap: 24px
    width: 100%

    &__item
        width: 100%
        position: relative
        display: flex
        justify-content: start
        gap: 12px

.info
    display: flex
    flex-direction: column

    &__name
        color: #000B26CC
        text-align: start
        opacity: .8

        &_checked
            opacity: 1

    &__desc
        color: #5C6374
        opacity: .8

        &_checked
            opacity: 1

.radiobutton
    position: absolute
    z-index: 3
    top: -12px
    left: -20px
    right: 0
    bottom: -12px
    border-radius: 16px

    &:hover
        background-color: #4F80FF1F

    &:checked
        opacity: 1
        background-color: #4F80FF1F

:global(.tk-list-item)
    padding: 16px !important

:global(.tk-list-item-button)
    border-radius: 16px !important
    &:hover
        background-color: #4F80FF1F !important

:global(.tk-list-item-button--selected)
    background-color: #4F80FF1F !important

:global(.tk-list)
    padding: 4px !important
    width: 210px !important
    background-color: rgb(243, 246, 253) !important
    border-radius: 8px !important
    gap: 2px !important


:global(.tk-dropdown-content)
    z-index: 5 !important 
