@mixin mobile
	@media (max-width: 767px)
		@content

@mixin tablet
	@media (max-width: 1024px)
		@content

@mixin laptop
	@media (max-width: 1440px)
		@content

@mixin big-screen
	@media (min-width: 1441px)
		@content
