@import "colors"
@import "devices"

@mixin scrollbar
	&::-webkit-scrollbar
		width: $scrollbar-width
		-ms-overflow-style: none

	&::-webkit-scrollbar-track
		background: inherit
		-webkit-border-radius: $scrollbar-border-radius
		border-radius: $scrollbar-border-radius

	&::-webkit-scrollbar-thumb
		-webkit-border-radius: $scrollbar-border-radius
		border-radius: $scrollbar-border-radius
		background: var(--tk-colors-surface-300)
		background-clip: padding-box

	&::-webkit-scrollbar-corner
		background-color: transparent

@mixin invisible-scrollbar
	&::-webkit-scrollbar
		width: 0
		-ms-overflow-style: none
