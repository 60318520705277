@charset "utf-8"
@import 'src/styles/reset'
@import "src/styles/mixins"
@import '../node_modules/@technokratos/prometheus-core/styles/index.sass'
@import '../node_modules/@technokratos/prometheus-core/styles/themes/css/tkThemeLight/_variables.css'
@import '../node_modules/@technokratos/prometheus-core/styles/themes/css/tkThemeDark/_variables.css'

hr
	margin: 0
	border: none

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

input[type=number]
	-moz-appearance: textfield
